@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@900&display=swap');

body {
  scroll-behavior: smooth;
}

:root {
  --primary-color: #5A81FA;
  --secondary-color: #2C3D8F;
  --third-color: #1F1F1F;
  --fourth-color: #6A6E83;
  --fifth-color: #CDDEFF;
  --sixth-color: #F8F9FD;
}


header {
  font-family: Rubik, sans-serif;
}

body {
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
}

.homeHeader {
  position: relative;
  display: flex;
  justify-content: center;
  /*transition: all 1s ease;  Add transition for smooth effect */
  scroll-snap-align: start;
  background: var(--primary-color);
}

.backSys {
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  background-color: none;
  margin-top: 0vh;
  position: relative;
  z-index: 2;
  width: 100%;

}


.placement {
  margin-top: -50vh;
}

.frontTex {
  margin-top: -10vh;
  position: absolute;
  z-index: 2;
  justify-content: center;
  align-items: center;
  color: #1f1f1f;
  height: 100vh;
}

.mainTitle {
  margin-top: 40vh;
  font-family: Montserrat, sans-serif;
  font-weight: 700;
}

.cursor {
  display: inline-block;
  width: 1px;
  background-color: black;
  animation: blink 1s step-start infinite;
}

.phoneBtn {
  position: absolute;
  z-index: 3;
  margin-top: 5rem;
  margin-left: 41vw;
  rotate: 12deg;
}

.moreBtn {
  font-size: 1rem;
  border: none;
  background: #F28500;
  border-radius: 0.8rem;
  padding: 0.8rem;
  font-weight: bold;
  cursor: pointer;
}

.moreBtn:hover {
  background: #000;
  color: #F28500;
}


.listStyle {
  width: 30vw;
  text-align: justify;
  background-color: white;
  border: 1px solid #dbdbdb;
  border-radius: 10px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  margin: 10px 0;
  padding: 15px;
  font-family: Montserrat, sans-serif;
  color: #262626;
  box-shadow: -10px 10px 15px rgba(255, 255, 255, 0.2), 10px 10px 15px rgba(0, 0, 0, 0.1);
  opacity: 0;
  transform: translateY(-50px);
  animation: fall 0.6s forwards;
}

.listStyle .title {
  font-size: 16px;
  font-weight: bold;
  margin-top: 5px;
}

.listStyle .author {
  font-size: 14px;
  color: #8e8e8e;
  margin-bottom: 5px;
}

.listStyle .content {
  font-size: 15px;
  line-height: 1.5;
}

@keyframes fall {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/*@keyframes slideLeftFade {
  to {
    opacity: 0;
    transform: translateX(-100%);
  }
}
*/

.works {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 100vh;
  height: 100vh;
  width: 100%;
  background: none;
  z-index: 1;
  scroll-snap-align: start;
}





.backSection {
  position: absolute;
  z-index: 1;
}

.relative {
  position: relative;
}

.project-cards {
  position: absolute;
  z-index: 2;

}

.about-section {
  height: 100vh;
  background-color: #f5f5f5;
}

.contact-section {
  height: 100vh;
  background-color: #f5f5f5;
  margin-top: 0;
  height: 100vh;
  background-color: #f0f0f0;
}


@media screen and (min-width: 301px) and (max-width: 900px) {


  .placement {
    margin-top: -70vh;
  }

  .frontTex {
    margin-top: -10vh;
    position: absolute;
    z-index: 2;
    justify-content: center;
    align-items: center;
    color: #000;
    height: 100vh;
  }

  .mainTitle {
    padding: 80px;
    margin-top: 30vh;
    font-size: 10px;
    font-family: Montserrat, sans-serif;
    font-weight: 700;
  }

  .cursor {
    display: inline-block;
    width: 1px;
    background-color: black;
    animation: blink 1s step-start infinite;
  }

  .phoneBtn {
    position: absolute;
    z-index: 3;
    margin-top: 5rem;
    margin-left: 45vw;
    rotate: 0deg;
  }

  .moreBtn {
    font-size: 1rem;
    border: none;
    background: #F28500;
    border-radius: 0.8rem;
    padding: 0.8rem;
    font-weight: bold;
    cursor: pointer;
  }

  .moreBtn:hover {
    background: #000;
    color: #F28500;
  }

  .phoneInfo {

  }

  .listStyle {
    width: 80vw;
    text-align: justify;
    background-color: white;
    border: 1px solid #dbdbdb;
    border-radius: 10px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
    margin: 10px 0;
    padding: 15px;
    font-family: Montserrat, sans-serif;
    color: #262626;
    box-shadow: -10px 10px 15px rgba(255, 255, 255, 0.2), 10px 10px 15px rgba(0, 0, 0, 0.1);
    opacity: 0;
    transform: translateY(-50px);
    animation: fall 0.6s forwards;
  }

  .listStyle .title {
    font-size: 16px;
    font-weight: bold;
    margin-top: 5px;
  }

  .listStyle .author {
    font-size: 14px;
    color: #8e8e8e;
    margin-bottom: 5px;
  }

  .listStyle .content {
    font-size: 15px;
    line-height: 1.5;
  }

  @keyframes fall {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  /*@keyframes slideLeftFade {
    to {
      opacity: 0;
      transform: translateX(-100%);
    }
  }

  .works {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 100vh;
    height: 100vh;
    width: 100%;
    background: #4169E1;
    z-index: 1;
    scroll-snap-align: start;
  }
  */
}
