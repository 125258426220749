
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap');

.nav-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 95%;
  padding: 10px 100px;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;

}

.nav-bar h1 {

  background: #000;
  padding: .5rem;
  color: white;
  border-radius: 5px;
  font-weight: 900;
  box-shadow: -10px 10px 15px rgba(255,255,255,0.2), 10px 10px 15px rgba(0,0,0,0.1);
  cursor: pointer;
}

.direction {
  display: flex;
  align-items: center;
}

.bar {
  visibility: hidden;
  color: #fff;
  background-color: #000;
  border: none;
  border-radius: 4px;

}

.nav-items {
  visibility: visible;
  display: flex;

}

.nav-items p {
  position: relative;
  margin-left: 4rem;
  color: #000;
  font-weight: bold;
  cursor: pointer;
  font-family: Montserrat;
  text-decoration: none;
  backdrop-filter: blur(10px); /* Adjust the blur strength as needed */
  -webkit-backdrop-filter: blur(10px); /* For Safari */
  padding: 10px;
  border-radius: 5px;

}


.nav-items p::before {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  width: 0%;
  height: 2px;
  background: #000;
  transition: .3s;
}

.nav-items p:hover::before {
  width: 100%;

}

.vitems {
  display: flex;
  align-items: center;

  flex-direction: row;
  color: #fff;

}

.hidden {
  visibility: hidden;
}

@media screen and (min-width: 301px) and (max-width: 900px) {
  .nav-bar {
    padding-left: 1rem;
    justify-content: space-between;

  }

  .direction {
    direction: rtl;
    display: block;
  }

  .bar {
    width: 3rem;
    visibility: visible;
    font-size: 1.5rem;
    margin: 1rem;
    padding: .5rem;

  }

  .nav-items {
    width: 70vw;
    visibility: hidden;
    position: absolute;
    z-index: 100;
    display: block;
    background-color: #000;
    border-radius: .8rem;
    padding: 2rem;
    margin: 1rem;
    justify-content: center;
    direction: ltr;

  }

  .nav-items p:hover::before {
    width: 0%;

  }

  .vitems {
    margin-left: 2rem;
  }

  .nav-items p {
    padding: 1rem;
    color: white;
    margin-left: 0;

  }

  .hidden {
    visibility: visible;
  }
}
