/* Footer.css */
.footer {
  background-color: #333;
  color: #fff;
  padding: 20px 0;
  text-align: center;
}

.footer-content {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.footer-left {
  margin: 10px;
}

.footer h3 {
  margin-bottom: 10px;
}

.footer p, .footer a {
  color: #fff;
  text-decoration: none;
}


.footer-bottom {
  margin-top: 20px;
}
