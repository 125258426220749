@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@900&display=swap');

:root {
  --primary-color: #5A81FA;
  --secondary-color: #2C3D8F;
  --third-color: #1F1F1F;
  --fourth-color: #6A6E83;
  --fifth-color: #CDDEFF;
  --sixth-color: #F8F9FD;
}

.contact-section {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100vh; /* Ensure it takes full height */
  width: 100%;
  background-color: red; /* This should apply */
  flex-direction: column;
}


.contact-medias {
  text-align: right;
  margin-right: 4rem;
}


.contact-card {
  width: 200px; /* Adjusted width to make the card larger in width */
  height: 100px; /* Adjusted height to make the card smaller in height */
  border: 1px var(--fourth-color) solid;
  border-radius: 8px;
  margin: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 30px;
  color: var(--third-color); /* Text color for better contrast */
  box-shadow: 4px 8px 12px rgba(0, 0, 0, 0.1);
  text-decoration: none;
  background-color: white; /* Added background color */
  transition: height .5s ease;
  cursor: pointer;
}

.card:hover {
  cursor: pointer;
  height: 220px; /* Adjusted height to make the card smaller in height */
}

.project-link {
  display: flex; /* Use Flexbox */
  align-items: center; /* Center vertically */
  justify-content: space-between; /* Center horizontally */
  margin-top: 16px;
  color: var(--fourth-color);
  text-decoration: none;
  border-radius: 8px;
  transition: background-color 0.3s ease-in-out;
  text-align: center;
  text-decoration: none;
}


.section-title {
  display: flex;

  text-align: right;
  padding: 0;
  margin-top: 4rem;
}

.section-title span {
  color: var(--primary-color);
}



.contact-container {
  /* Add any necessary styling here */
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-form {
  width: 350px;
  margin: 0 auto;
  background: none;
}

.contact-form h4 {
  color: var(--fourth-color);
}

.form-group {
  margin-bottom: 15px;
}


/*
.label {
  display: flex;
  justify-content: center;
  margin-bottom: 0 0 20px 20px;
  padding: 2px;
  font-weight: bold;
  color: var(--fourth-color);
  background: red;
  Adjust padding as needed
}
*/


.form-group input,
.form-group textarea {
  width: 100%;
  padding: 12px;
  border: 2px solid var(--fourth-color);
  border-radius: 8px;
  box-sizing: border-box;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
  background: none;
  outline: none;
}

.form-group textarea {
  height: 150px;
  resize: none;
}

.form-group .error {
  color: red;
  font-size: 0.875em;
  margin-top: 5px;
}

button {
  padding: 10px;
  color: #fff;
  background-color: var(--primary-color);
  border: 2px #000 solid;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: bold;
}

button:hover {
  background-color: var(--secondary-color);
}

@media screen and (min-width: 301px) and (max-width: 900px) {
  .contact-container {
    display: flex;
    flex-direction: column-reverse;
  }

  .contact-medias {
    margin-right: 0;
    margin-top: 4rem;
  }
}
