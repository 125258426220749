@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@900&display=swap');

:root {
  --primary-color: #5A81FA;
  --secondary-color: #2C3D8F;
  --third-color: #1F1F1F;
  --fourth-color: #6A6E83;
  --fifth-color: #CDDEFF;
  --sixth-color: #F8F9FD;
}


.about-section {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}




@keyframes moveDown {
  from {
    transform: translateY(-500px);
  }

  to {
    transform: translateY(0px);
  }
}

.rec-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.rec1 {
  height: 100px;
  width: 100px;
  border-radius: 10px;
  border: 3px var(--sixth-color) solid;
  background: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;
  color: var(--fifth-color);
}



.delay1 {
  animation-delay: 0.2s;
}

.delay2 {
  animation-delay: 0.4s;
}

.delay3 {
  animation-delay: 0.6s;
}

.animate {
  animation-name: moveDown;
  animation-duration: 3s;
  animation-fill-mode: backwards;
}

.info-container {
  text-align: justify;
  font-size: 18px;
  font-family: monospace;
  font-weight: 400;
  width: 50%;
}

@media screen and (min-width: 301px) and (max-width: 900px) {
  .info-container {
    text-align: center;
    width: 85%;
  }
}
