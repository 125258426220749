@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@900&display=swap');

:root {
  --primary-color: #5A81FA;
  --secondary-color: #2C3D8F;
  --third-color: #1F1F1F;
  --fourth-color: #6A6E83;
  --fifth-color: #CDDEFF;
  --sixth-color: #F8F9FD;
}

/* src/projects.css */

.project-section {

  background: var(--primary-color);

  height: 100vh;

}

.project-container {
  position: relative;
  display: flex;
  flex-direction: column; /* Stack children vertically */
  align-items: center; /* Center horizontally */
  background: none;
  padding: 20px; /* Adjust padding as needed */
  height: 100%;
}

.lap-container {
  background: none;
  position: absolute;
  z-index: 2;
  display: flex;
  justify-content: center; /* Center the Laptop component */
  margin-bottom: 20px; /* Space between lap-container and cards-container */
}

.cards-container {
  margin-top: 4rem;
  position: absolute;
  z-index: 1;
  display: flex;
  flex-wrap: wrap; /* Allow cards to wrap to the next line if they don't fit in one row */
  justify-content: center;
  align-items: center;
}

.project-card {
  width: 250px; /* Adjusted width to make the card larger in width */
  height: 250px; /* Adjusted height to make the card smaller in height */
  border-radius: 8px;
  margin: 16px;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center content horizontally */
  color: var(--third-color); /* Text color for better contrast */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  text-shadow: none;
  background-color: white; /* Added background color */
}

.project-image {
  margin-top: 10px;
  border-radius: 10px;
  width: 90%;
  height: 150px;
}

.project-content {
  font-family: monospace;
  font-weight: bold;
  padding: 16px;
  width: 100%;
  box-sizing: border-box;
  text-align: left; /* Align text to the left */
}

.project-title {
  font-size: 1.5em;
  margin: 0;
}

.project-description {
  font-size: 1em;
  margin: 8px 0 0;
}

.project-link {
  display: flex; /* Use Flexbox */
  align-items: center; /* Center vertically */
  justify-content: space-between; /* Center horizontally */
  margin-top: 16px;
  color: var(--third-color);
  text-decoration: none;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  text-align: center;
}

.project-link:hover {
  color: var(--secondary-color);
}

@media screen and (min-width: 301px) and (max-width: 900px) {
  .project-section {
    padding: 0px;
  }
}
