@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@900&display=swap');

:root {
  --primary-color: #5A81FA;
  --secondary-color: #2C3D8F;
  --third-color: #1F1F1F;
  --fourth-color: #6A6E83;
  --fifth-color: #CDDEFF;
  --sixth-color: #F8F9FD;
}


.services-section {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;

}

.section-title {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.section-title h4 {
  color: var(--fourth-color);
}


.card-container {
  display: flex;
  flex-direction: row;
  text-decoration: none;
}

.card {
  width: 200px; /* Adjusted width to make the card larger in width */
  height: 200px; /* Adjusted height to make the card smaller in height */
  border: 2px var(--primary-color) solid;
  border-radius: 8px;
  margin: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  padding: 30px;
  color: var(--third-color); /* Text color for better contrast */
  box-shadow: 4px 8px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  text-decoration: none;

  background-color: white; /* Added background color */
  transition: height .5s ease;
}

.card:hover {
  cursor: pointer;
  height: 220px; /* Adjusted height to make the card smaller in height */
}

.project-link {
  display: flex; /* Use Flexbox */
  align-items: center; /* Center vertically */
  justify-content: space-between; /* Center horizontally */
  margin-top: 16px;
  color: var(--fourth-color);
  text-decoration: none;
  border-radius: 8px;
  transition: background-color 0.3s ease-in-out;
  text-align: center;
  text-decoration: none;
}

.icon {
  font-size: 40px;
}


@media screen and (min-width: 301px) and (max-width: 900px) {
  .card-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

  }

  .card {
    margin: 0px;
    width: 120px;
    height: 140px;
    margin: 5px;
    padding: 20px;

  }
}
